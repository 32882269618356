@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AmikaExtraBold";
  src: url('./fonts/amika_font.woff2') format('woff2'), url('./fonts/amika_font.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "AmikaExtraBold";
  margin: 0;
}

a {
  text-decoration: none;
}

body .carousel .control-next.control-arrow::before {
  border-left: 8px solid #000 !important;
}

body .carousel .control-prev.control-arrow::before {
  border-right: 8px solid #000 !important;
}
